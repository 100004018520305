import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AnalyticsSlice {
    context: string;
}

const initialState: AnalyticsSlice = {
    context: "/",
};

export const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setContext: (state, action: PayloadAction<AnalyticsSlice>) => {
            state.context = action.payload.context;
        },
    },
});

export const { setContext } = analyticsSlice.actions;
