import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type NotificationSubscriptionSlice = Record<string, boolean>;

const initialState: NotificationSubscriptionSlice = {};

export const notificationSubscriptionSlice = createSlice({
    name: "notificationSubscription",
    initialState,
    reducers: {
        toggle: (state, action: PayloadAction<{ id: string; isSubscribed: boolean }>) => {
            state[action.payload.id] = action.payload.isSubscribed;
        },
    },
});

export const { toggle } = notificationSubscriptionSlice.actions;
