import { LiveEventType } from "../../generated/graphql";
import { LiveEventStages } from "./live-event-stages";

export const LiveEventJourney = {
    get(eventType: LiveEventType | undefined): LiveEventStages[] {
        switch (eventType) {
            case LiveEventType.TimedReleaseWithDrawEnd:
                return [
                    LiveEventStages.COUNTDOWN_TO_START,
                    LiveEventStages.LIVE_STREAM,
                    LiveEventStages.WAITING_FOR_DRAW,
                    LiveEventStages.DRAW,
                ];
            case LiveEventType.Draw:
                return [LiveEventStages.COUNTDOWN_TO_START, LiveEventStages.WAITING_FOR_DRAW, LiveEventStages.DRAW];
            case LiveEventType.TimedRelease:
                return [LiveEventStages.COUNTDOWN_TO_START, LiveEventStages.LIVE_STREAM];
            case LiveEventType.Stream:
                return [LiveEventStages.COUNTDOWN_TO_START, LiveEventStages.LIVE_STREAM];
            case LiveEventType.Unspecified:
            default:
                return [];
        }
    },
};
