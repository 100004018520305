import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DisplayableAccountFragment } from "../../generated/graphql";

export interface AccountSlice {
    account?: DisplayableAccountFragment;
    loggedIn: boolean;
    status: "LOGGED_OUT" | "EMAIL_SENT" | "LOGGED_IN" | "UNKNOWN";
}

const initialState: AccountSlice = {
    account: undefined,
    loggedIn: false,
    status: "UNKNOWN",
};

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        authenticate: (state, action: PayloadAction<DisplayableAccountFragment>) => {
            state.account = { ...action.payload };
            state.status = "LOGGED_IN";
            state.loggedIn = true;
        },
        setAuthStatus: (state, action: PayloadAction<AccountSlice["status"]>) => {
            state.status = action.payload;
        },
        updateAccount: (state, action: PayloadAction<DisplayableAccountFragment>) => {
            if (!state.account) return;
            state.account = { ...action.payload };
            return;
        },
    },
});

export const { authenticate, setAuthStatus, updateAccount } = accountSlice.actions;
