import Head from "next/head";

import { GoogleTag } from "../scripts/GoogleAnalytics/TrackingTag";
import { publicRuntimeConfig } from "../utils/config";

const defaultTitle = "Avant Arte Collective";
const defaultDescription =
    "Join the world's most engaged online art community to get access to exclusive content, live events and edition allocations.";
const baseUrl = `https://${publicRuntimeConfig.domain}/`;
const logo = `https://${publicRuntimeConfig.domain}/og-image.jpg`;

type PageHeadProps = {
    title?: string;
    urlPath: string;
    description?: string;
    image?: string | null;
    noIndex?: boolean;
};

// DefaultHead should be used in app level render for defaults meta.
export const DefaultHead = () => (
    <>
        <Head>
            <meta
                content="jwYmMWytDBnc_TgdejtJIRpU4zOQiC4LCDHUAZObLSo"
                name="google-site-verification"
            />

            <title key="title">{defaultTitle}</title>
            <meta
                key="og:title"
                content={defaultTitle}
                property="og:title"
            />

            <meta
                key="Description"
                content={defaultDescription}
                name="Description"
            />
            <meta
                key="og:description"
                content={defaultDescription}
                property="og:description"
            />

            <link
                key="canonical"
                href={baseUrl}
                rel="canonical"
            />
            <meta
                key="og:url"
                content={baseUrl}
                property="og:url"
            />

            <meta
                key="og:image"
                content={logo}
                property="og:image"
            />

            <meta
                content="website"
                property="og:type"
            />
            <meta
                content="Avant Arte Collective"
                property="og:site_name"
            />
            <meta
                content="@avant_arte"
                name="twitter:site"
            />
            <meta
                content="summary_large_image"
                name="twitter:card"
            />
            <link
                color="#ffffff"
                href="https://avantarte.com/safari-pinned-tab.svg"
                rel="mask-icon"
            />
            <link
                href="https://avantarte.com/favicon.ico?v=2"
                rel="icon"
            />
            <meta
                content="#ffffff"
                name="msapplication-TileColor"
            />
            <meta
                content="#ffffff"
                name="theme-color"
            />
            <link
                href="https://avantarte.com/apple-touch-icon.png"
                rel="apple-touch-icon"
                sizes="180x180"
            />
            <link
                href="https://avantarte.com/favicon-32x32.png"
                rel="icon"
                sizes="32x32"
                type="image/png"
            />
            <link
                href="https://avantarte.com/favicon-16x16.png"
                rel="icon"
                sizes="16x16"
                type="image/png"
            />
        </Head>
        <GoogleTag />
    </>
);

export const PageHead: React.VFC<PageHeadProps> = ({ description, image, noIndex, title, urlPath }) => {
    const url = `${baseUrl + urlPath}/`;

    return (
        <Head>
            {noIndex && (
                <meta
                    content="noindex"
                    name="robots"
                />
            )}

            {title && (
                <>
                    <title key="title">
                        {title} - {defaultTitle}
                    </title>
                    <meta
                        key="og:title"
                        content={`${title} - ${defaultTitle}`}
                        property="og:title"
                    />
                </>
            )}

            <link
                key="canonical"
                href={url}
                rel="canonical"
            />
            <meta
                key="og:url"
                content={url}
                property="og:url"
            />

            {description && (
                <>
                    <meta
                        key="Description"
                        content={description}
                        name="Description"
                    />
                    <meta
                        key="og:description"
                        content={description}
                        property="og:description"
                    />
                </>
            )}

            {image && (
                <meta
                    key="og:image"
                    content={image}
                    property="og:image"
                />
            )}
        </Head>
    );
};
