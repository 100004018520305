import { FC } from "react";

import { DefaultHead } from "../PageHead";

export const Layout: FC = ({ children }) => {
    return (
        <>
            <DefaultHead />
            <div className="min-h-screen">{children}</div>
        </>
    );
};
