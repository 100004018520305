import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    from,
    HttpOptions,
    InMemoryCache,
    Operation,
    split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GetServerSidePropsContext } from "next";

import { authLink } from "./hooks/useAuth";
import { publicRuntimeConfig } from "./utils/config";

const cache = new InMemoryCache();

export const client = (ctx?: GetServerSidePropsContext) => {
    const httpOpts: HttpOptions = {
        uri: `https://${publicRuntimeConfig.api}`,
        credentials: "include",
    };

    if (ctx) {
        // CloudRun does not prevent header spoofing for ip forwarded
        // Only the last value is accepted to be the prev hop
        // "1.1.1.1, 2.2.2.2, 3.3.3.3" => last trusted proxy/client ip is 3.3.3.3
        const ips = ((ctx.req.headers["x-forwarded-for"] as string) || "").split(",");

        httpOpts.headers = {
            Cookie: ctx.req.headers.cookie || "",
            "X-Nextjs-Verify": process.env.GQL_API_IP_FORWARDED_HEADER_VERIFY || "",
            "X-Nextjs-Forwarded-For": ips[ips.length - 1].trim(),
        };
    }

    const httpLink = createHttpLink(httpOpts);

    let splitLink: ApolloLink = httpLink;
    if (typeof window !== "undefined") {
        const wsLink = new GraphQLWsLink(
            createClient({
                url: `wss://${publicRuntimeConfig.api}`,
                lazy: true,
                lazyCloseTimeout: 10000, // 10s
            }),
        );

        const testSplit = ({ query }: Operation) => {
            const definition = getMainDefinition(query);
            return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        };

        splitLink = split(testSplit, wsLink, httpLink);
    }

    return new ApolloClient({
        ssrMode: typeof window === "undefined",
        cache,
        connectToDevTools: true,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "cache-and-network",
            },
        },
        link: from([authLink, splitLink]),
    });
};
