import mixpanel, { Dict } from "mixpanel-browser";
import { useEffect } from "react";

import { DisplayableAccountFragment } from "../../generated/graphql";
import { store } from "../../store";
import { publicRuntimeConfig } from "../config";

mixpanel.init(publicRuntimeConfig.mixPanelAccount, {
    debug: !publicRuntimeConfig.isProduction,
});

export type Page =
    | "all"
    | "code"
    | "collections"
    | "collectors index"
    | "account"
    | "events"
    | "live event"
    | "home"
    | "artist"
    | "artists index"
    | "create post form"
    | "collection edit form"
    | "collection new form"
    | "post"
    | "posts"
    | "messages"
    | "series";

function toTitleCase(str: string) {
    return str
        .replaceAll("-", " ")
        .replaceAll("_", " ")
        .split(" ")
        .filter((w) => w)
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(" ");
}

const trackEvent = (title: string, action: string, properties?: Dict) => {
    mixpanel.track(toTitleCase(title), { action, ...properties, context: store.getState().analytics.context });
};

const trackError = (title: string, action: string, error: string, properties?: Dict) => {
    mixpanel.track(toTitleCase(title), { error, action, ...properties, context: store.getState().analytics.context });
};

const trackPageView = (page: Page, properties?: Dict) => {
    mixpanel.track(toTitleCase(page), { action: "page view", ...properties });
};

const useTrackPageView = (page: Page) => {
    useEffect(() => {
        trackPageView(page);
    }, [page]);
};

const identifyUser = (account: DisplayableAccountFragment) => {
    mixpanel.identify(account.id);
    mixpanel.people.set({
        $email: account.email,
        $avatar: account.avatar,
        $name: account.fullName,
        $created: account.createdAt,
        $distinct_id: account.id,
        userName: account.username,
    });
};

export const analytics = {
    identifyUser,
    useTrackPageView,
    trackPageView,
    trackEvent,
    trackError,
};
