import getConfig from "next/config";

export type Config = {
    publicRuntimeConfig: {
        api: string;
        mixPanelAccount: string;
        isProduction: boolean;
        domain: string;
        recaptchaV2: string;
        recaptchaV3: string;
        streamAPIKey: string;
        googleClientId: string;
    };
};

const { publicRuntimeConfig } = getConfig() as Config;
export { publicRuntimeConfig };
