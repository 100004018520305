import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum CollectionsOrder {
    Featured = "Featured",
    Recent = "Recent",
}

export interface OrderState {
    activeOrder: CollectionsOrder;
}

export const collectionsOrderSlice = createSlice({
    name: "collectionOrder",
    initialState: () => {
        return { activeOrder: CollectionsOrder.Recent };
    },
    reducers: {
        changeOrder: (state, action: PayloadAction<CollectionsOrder>) => {
            state.activeOrder = action.payload;
        },
    },
});

export const { changeOrder } = collectionsOrderSlice.actions;
