/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FeedID = "timeline" | "collections" | string; // acc_id, art_id, ...

export type FeedData<T extends { id: string }> = {
    cursor: string;
    order: string[];
    data: Record<string, T>;
};

export type FeedSlice<T extends { id: string }> = Record<FeedID, FeedData<T>>;

export const genFeedDefault = () => ({ cursor: "", order: [], data: {} });

const initialState: FeedSlice<{ id: string }> = {
    timeline: genFeedDefault(),
    collections: genFeedDefault(),
};

export const feedSlice = createSlice({
    name: "feed",
    initialState,
    reducers: {
        removeFromFeed: (state, { payload }: PayloadAction<{ id: string }>) => {
            Object.values(state).forEach((feed) => {
                feed.order = feed.order.filter((id) => id !== payload.id);
            });
        },
        clearFeeds: (state) => {
            Object.keys(state).forEach((feedID) => {
                state[feedID] = genFeedDefault();
            });
        },
        loadFeed: (
            state,
            {
                payload,
            }: PayloadAction<{
                feedID: FeedID;
                data: Array<{ id: string }>;
                cursor: string;
                init?: boolean;
            }>,
        ) => {
            if (!state[payload.feedID]) {
                state[payload.feedID] = genFeedDefault();
            }

            const feed = state[payload.feedID];

            if (payload.init) {
                // if ssr tries to rehydrate state during page navigation, keep the current cursor
                if (!feed.cursor) feed.cursor = payload.cursor;
            } else {
                feed.cursor = payload.cursor;
            }

            // filter potential duplicates from the bottom of the list to maintain scroll position
            // ssr nav push duplicate/new items from top of the list
            const postIds = payload.data.map((p) => p.id);

            if (payload.init && !feed.order.length) {
                feed.order = postIds;
            } else {
                feed.order = [...feed.order, ...postIds].filter((p, idx, arr) => arr.indexOf(p) === idx);
            }

            payload.data.forEach((p) => {
                feed.data[p.id] = p;
            });
        },
    },
});

export const { clearFeeds, loadFeed, removeFromFeed } = feedSlice.actions;
