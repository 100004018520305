import { createSlice } from "@reduxjs/toolkit";

export interface LiveChatState {
    isOpen: boolean;
}

const initialState: LiveChatState = {
    isOpen: false,
};

export const liveChatSlice = createSlice({
    name: "liveChat",
    initialState,
    reducers: {
        openLiveChat: (state) => {
            state.isOpen = true;
        },
        closeLiveChat: (state) => {
            state.isOpen = false;
        },
        toggleLiveChat: (state) => {
            state.isOpen = !state.isOpen;
        },
    },
});

export const { closeLiveChat, openLiveChat, toggleLiveChat } = liveChatSlice.actions;
