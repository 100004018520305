import Script from "next/script";

export const GoogleTag = () => (
    <>
        <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-36WSBM00HN"
            strategy="afterInteractive"
        />
        <Script
            id="google-analytics"
            strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-36WSBM00HN', {
            page_path: window.location.pathname,
          });
        `}
        </Script>
    </>
);
