import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LiveEventType } from "../../generated/graphql";
import { LiveEventJourney } from "./live-event-journey";
import { LiveEventStages } from "./live-event-stages";

export interface EventState {
    activeStage: LiveEventStages;
    stages: LiveEventStages[];
}

const initialState: EventState = {
    activeStage: LiveEventStages.COUNTDOWN_TO_START,
    stages: [],
};

export const liveEventSlice = createSlice({
    name: "liveEvent",
    initialState,
    reducers: {
        setStages: (state, action: PayloadAction<LiveEventType | undefined>) => {
            state.stages = LiveEventJourney.get(action.payload);
        },
        setActiveStage: (state, action: PayloadAction<LiveEventStages>) => {
            state.activeStage = action.payload;
        },
        goToNextStage: (state) => {
            const activeStageIndex = state.stages.indexOf(state.activeStage);

            if (activeStageIndex === state.stages.length - 1) return;
            state.activeStage = state.stages[activeStageIndex + 1];
        },
        goToPreviousStage: (state) => {
            const activeStageIndex = state.stages.indexOf(state.activeStage);

            if (activeStageIndex === 0) return;
            state.activeStage = state.stages[activeStageIndex - 1];
        },
    },
});

export const { reducer } = liveEventSlice;
export const { goToNextStage, setActiveStage, setStages } = liveEventSlice.actions;
