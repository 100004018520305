import { IncomingMessage } from "http";
import { NextRequest } from "next/server";
import Cookies from "universal-cookie";

import { publicRuntimeConfig } from "./config";

const loggedInCookie = publicRuntimeConfig.isProduction ? "aa-logged-in" : "aa-logged-in-dev";

const getCookie = (key: string, req?: NextRequest | IncomingMessage) => {
    const uc = new Cookies((req as NextRequest)?.cookies || (req as IncomingMessage)?.headers?.cookie);
    return uc.getAll<Record<string, string | undefined>>()[key];
};

export const isLoggedInCookie = (req?: NextRequest | IncomingMessage) => getCookie(loggedInCookie, req);
