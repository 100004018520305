export const setUTMs = (params: URLSearchParams) => {
    if (typeof window === "undefined") return;

    const campaign = params.get("utm_campaign");
    const medium = params.get("utm_medium");
    const source = params.get("utm_source");

    if (campaign) window.sessionStorage.setItem("utm_campaign", campaign);
    if (medium) window.sessionStorage.setItem("utm_medium", medium);
    if (source) window.sessionStorage.setItem("utm_source", source);
};

export const getUTMs = () => {
    const utms: { origin: string; originUTMCampaign?: string; originUTMMedium?: string; originUTMSource?: string } = {
        origin: "collective",
    };

    if (typeof window !== "undefined") {
        const campaign = window.sessionStorage.getItem("utm_campaign");
        const medium = window.sessionStorage.getItem("utm_medium");
        const source = window.sessionStorage.getItem("utm_source");

        if (campaign) utms.originUTMCampaign = campaign;
        if (medium) utms.originUTMMedium = medium;
        if (source) utms.originUTMSource = source;
    }

    return utms;
};
