import { ApolloLink } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/nextjs";
import { GraphQLError } from "graphql";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
    DisplayableAccountFragment,
    useGetAccountLazyQuery,
    useLoginMutation,
    useLogoutMutation,
    useUseLoginCodeMutation,
} from "../generated/graphql";
import { authenticate, setAuthStatus } from "../state/account/account-slice";
import { openSignup } from "../state/auth-wall/auth-wall-slice";
import { store, useAppDispatch } from "../store";
import { analytics } from "../utils/analytics/analytics";
import { isLoggedInCookie } from "../utils/cookies";

interface AuthType {
    loading: boolean;
    account?: DisplayableAccountFragment;
    error?: Error;

    login(email: string): Promise<void>;
    loginWithCode(email: string, code: string): Promise<void>;
    logout(): void;
}

const AuthContext = createContext<AuthType>({} as AuthType);

export const authLink = ApolloLink.from([
    onError(({ graphQLErrors }) => {
        graphQLErrors?.forEach((err) => {
            if (err.extensions?.code === "UNAUTHORIZED") {
                store.dispatch(openSignup());
            }
        });
    }),
]);

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [loginMutation, loginMutationState] = useLoginMutation();
    const [logoutMutation] = useLogoutMutation();
    const [loginCodeMutation, loginCodeMutationState] = useUseLoginCodeMutation();
    const [getAccount, getAccountState] = useGetAccountLazyQuery();
    const [error, setError] = useState<GraphQLError>();
    const [account, setAccount] = useState<DisplayableAccountFragment>();
    const dispatch = useAppDispatch();

    const login = useCallback(
        async (email: string) => {
            const res = await loginMutation({ variables: { email }, errorPolicy: "all" });
            if (res.errors as GraphQLErrors) {
                setError((res.errors as GraphQLErrors)[0]);
                return;
            }
            dispatch(setAuthStatus("EMAIL_SENT"));
        },
        [dispatch, loginMutation],
    );

    const loginWithCode = useCallback(
        async (email: string, code: string) => {
            const res = await loginCodeMutation({ variables: { email, code }, errorPolicy: "all" });
            if (res.errors as GraphQLErrors) {
                setError((res.errors as GraphQLErrors)[0]);
                return;
            }

            if (res.data) {
                const loggedInAccount: DisplayableAccountFragment = { ...res.data.useLoginCode.account };
                setAccount(loggedInAccount);
                dispatch(authenticate(loggedInAccount));
            }
        },
        [dispatch, loginCodeMutation],
    );

    const logout = useCallback(async () => {
        analytics.trackEvent("Logged Out", "click");
        setAccount(undefined);
        Sentry.configureScope((Scope) => Scope.setUser(null));
        await logoutMutation();
        // removing cookies takes some time apparently and hard refresh to avoid state leak
        setTimeout(() => window.location.assign("/"), 10);
    }, [logoutMutation]);

    useEffect(() => {
        if (!account && isLoggedInCookie()) {
            getAccount({ variables: { withCollectionsCount: false } }).then((data) => {
                if (data.data?.account?.id) {
                    dispatch(setAuthStatus("LOGGED_IN"));
                    dispatch(authenticate({ ...data.data.account }));
                    Sentry.setUser({ id: data.data.account.id });
                }
            });
        } else {
            dispatch(setAuthStatus("LOGGED_OUT"));
        }
    }, [account, dispatch, getAccount]);

    if (getAccountState.data?.account && !account) {
        setAccount(getAccountState.data.account);
    }

    const loading = loginMutationState.loading || loginCodeMutationState.loading || getAccountState.loading;

    const ctx = useMemo(
        () => ({
            login,
            loginWithCode,
            loading,
            logout,
            error,
            account,
        }),
        [account, error, loading, login, loginWithCode, logout],
    );

    return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
