import * as Sentry from "@sentry/nextjs";

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn:
            process.env.SENTRY_DSN ||
            process.env.NEXT_PUBLIC_SENTRY_DSN ||
            "https://0420452642aa4227b699fe0e8044f978@o1182543.ingest.sentry.io/6322983",
        tracesSampleRate: 0.3,
    });
}
