import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CollectionCollectorsListOrder } from "../../generated/graphql";

export interface OrderState {
    activeOrder: CollectionCollectorsListOrder;
}

const initialState: OrderState = {
    activeOrder: CollectionCollectorsListOrder.Top,
};

export const collectorsOrderSlice = createSlice({
    name: "collectorsOrder",
    initialState,
    reducers: {
        changeOrder: (state, action: PayloadAction<CollectionCollectorsListOrder>) => {
            state.activeOrder = action.payload;
        },
    },
});

export const { changeOrder } = collectorsOrderSlice.actions;
