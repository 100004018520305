import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AuthWallSteps = "" | "loginOptions" | "signupOptions" | "login" | "signup" | "code" | "account";

export type AuthWallSlice = {
    email: string;
    step: AuthWallSteps;
    prev: AuthWallSteps;
};

const initialState: AuthWallSlice = {
    step: "",
    email: "",
    prev: "",
};

export const authWallSlice = createSlice({
    name: "authWall",
    initialState,
    reducers: {
        openLogin: (state) => {
            state.prev = state.step;
            state.step = "loginOptions";
        },
        openSignup: (state) => {
            state.prev = state.step;
            state.step = "signupOptions";
        },
        openCode: (state, action: PayloadAction<AuthWallSlice["email"]>) => {
            state.prev = state.step;
            state.step = "code";
            state.email = action.payload;
        },
        setAuthStep: (state, action: PayloadAction<AuthWallSteps>) => {
            state.prev = state.step;
            state.step = action.payload;
        },
    },
});
export const { openCode, openLogin, openSignup, setAuthStep } = authWallSlice.actions;
