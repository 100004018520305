import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { accountSlice } from "./state/account/account-slice";
import { analyticsSlice } from "./state/analytics/analytics";
import { artistsOrderSlice } from "./state/artists/artists-order";
import { authWallSlice } from "./state/auth-wall/auth-wall-slice";
import { collectionsOrderSlice } from "./state/collections/collections-order";
import { collectorsOrderSlice } from "./state/collectors/collectors-order";
import { feedSlice } from "./state/feed/feed-slice";
import { liveChatSlice } from "./state/live-chat/live-chat-slice";
import { liveEventSlice } from "./state/live-event/live-event-slice";
import { notificationSubscriptionSlice } from "./state/notification-subscription/notification-subscription-slice";

export const store = configureStore({
    reducer: {
        account: accountSlice.reducer,
        analytics: analyticsSlice.reducer,
        artistsOrder: artistsOrderSlice.reducer,
        authWall: authWallSlice.reducer,
        collectionsOrder: collectionsOrderSlice.reducer,
        collectorsOrder: collectorsOrderSlice.reducer,
        liveChat: liveChatSlice.reducer,
        liveEvent: liveEventSlice.reducer,
        feed: feedSlice.reducer,
        notificationSubscription: notificationSubscriptionSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
