import "../styles/sunset.css";

import { ApolloProvider } from "@apollo/client";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { useEffect } from "react";

import { client } from "../client";
import { Layout } from "../components/Layout/SunsetLayout";
import { setUTMs } from "../utils/utm";

const MyApp = ({ Component, pageProps }: AppProps) => {
    useEffect(() => {
        if (typeof window !== "undefined") {
            setUTMs(new URLSearchParams(window.location.search));
        }
    }, []);

    return (
        <ApolloProvider client={client(undefined)}>
            <Layout>
                <Component {...pageProps} />
            </Layout>
        </ApolloProvider>
    );
};

export default appWithTranslation(MyApp);
