import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CollectionListArtistsOrder } from "../../generated/graphql";

export interface OrderState {
    activeOrder: CollectionListArtistsOrder;
}

const initialState: OrderState = {
    activeOrder: CollectionListArtistsOrder.Top,
};

export const artistsOrderSlice = createSlice({
    name: "artistsOrder",
    initialState,
    reducers: {
        changeOrder: (state, action: PayloadAction<CollectionListArtistsOrder>) => {
            state.activeOrder = action.payload;
        },
    },
});

export const { changeOrder } = artistsOrderSlice.actions;
